import { faBars } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AdjustmentsIcon } from '@heroicons/react/outline'
import { SearchIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'

import { UserProfileData } from '@/api-utils/service-requests/user-sessions'
import { useSearch } from '@/hooks/useSearchContext'
import { useSideMenu } from '@/hooks/useSideMenu'
import { LocationInformation } from '@/models/types'
import { Heading } from '@/ui/Typography'
import { getSelectedTheme } from '@/utils/location-center-helpers'

import { LanguageSelectorButton } from '../menu-group-picker/language-selector-button'
import { BackButton } from './back-button'
import { Menu } from './menu'
import { MenuButton } from './menu-button'

export enum NavbarButton {
  Back = 'back',
  Menu = 'menu',
  Search = 'search',
  Filter = 'filter',
  Language = 'language',
  User = 'user',
}

export type NavbarOptions = {
  leftButtons?: NavbarButton[]
  rightButtons?: NavbarButton[]
  title?: string | null
  goBackToHref?: string
  transparentBackground?: boolean
  buttonAction?: () => void
  shouldUseRouterBack?: boolean
  invertColor?: boolean
  extraSidePadding?: boolean
}

type Props = {
  locationInfo: Pick<LocationInformation, 'themes' | 'locationCenters'>
  options?: NavbarOptions
}

export const Navbar = ({ locationInfo, options }: Props) => {
  const {
    leftButtons = [NavbarButton.Back],
    rightButtons = [NavbarButton.User],
    title,
    goBackToHref,
    invertColor = false,
    extraSidePadding = false,
  } = options || {}

  const { toggleSearch, togglePreferenceDrawer } = useSearch()
  const selectedTheme = getSelectedTheme(locationInfo)
  const [openMenu, setOpenMenu] = useState<Menu>()
  const sideMenu = useSideMenu()

  // NOTE(christoffer) Seems like we have some inconsistencies in side padding on pages.
  // At the time of writing I've only seen two different padding:s, so calling this "extra
  // padding" as a one-off case, rather than trying to generalize it.
  const sidePaddingSizeValueL = extraSidePadding ? 'ml-[2.0rem]' : 'ml-3'
  const sidePaddingSizeValueR = extraSidePadding ? 'mr-[2.0rem]' : 'mr-3'

  const renderButton = (button: NavbarButton) => {
    switch (button) {
      case NavbarButton.Menu:
        return (
          <MenuButton
            key="menu"
            selectedTheme={selectedTheme}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
          />
        )
      case NavbarButton.Back:
        return (
          <BackButton
            key="back"
            goBackToHref={goBackToHref}
            buttonAction={options?.buttonAction}
            invertColor={invertColor}
            shouldUseRouterBack={options?.shouldUseRouterBack}
            selectedTheme={selectedTheme}
          />
        )
      case NavbarButton.Search:
        return (
          <button
            key="search"
            onClick={toggleSearch}
            className={
              'w-6 h-6 text-base font-medium ' +
              (selectedTheme ? 'text-[color:var(--brand-text-on-primary-color)]' : 'text-black')
            }>
            <SearchIcon className={'w-5 h-5'} />
          </button>
        )
      case NavbarButton.Filter:
        return (
          <button
            key="filter"
            onClick={togglePreferenceDrawer}
            className={
              'w-5 h-5 text-base font-medium ' +
              (selectedTheme ? 'text-[color:var(--brand-text-on-primary-color)]' : 'text-black')
            }>
            <AdjustmentsIcon />
          </button>
        )
      case NavbarButton.User:
        return (
          <UserMenuButton
            key="user"
            userProfile={sideMenu.userProfile}
            transparentBackground={options?.transparentBackground ?? false}
          />
        )
      case NavbarButton.Language:
        return (
          <button
            key="filter"
            onClick={togglePreferenceDrawer}
            className={
              'w-5 h-5 text-base font-medium ' +
              (selectedTheme ? 'text-[color:var(--brand-text-on-primary-color)]' : 'text-black')
            }>
            <LanguageSelectorButton />
          </button>
        )
      default:
        return null
    }
  }

  const UserMenuButton = (props: {
    transparentBackground: boolean
    userProfile: UserProfileData | null
  }) => {
    const { transparentBackground } = props
    const styleClass = transparentBackground ? `bg-white` : `bg-transparent`
    const textColor = transparentBackground
      ? 'text-default'
      : selectedTheme
      ? 'text-[color:var(--brand-text-on-primary-color)]'
      : 'text-black'
    return (
      <button
        className={`${textColor} inline-flex rounded-full ${styleClass}`}
        onClick={() => {
          sideMenu.setIsSheetOpen(true)
        }}>
        <div className="w-8 h-8 m-auto flex items-center justify-center ">
          <FontAwesomeIcon icon={faBars} />
        </div>
      </button>
    )
  }

  // Special case for the navbar - white background with black text when no theme
  const useWhiteNavbar = !selectedTheme && !options?.transparentBackground

  return (
    <nav
      className={`${
        options?.transparentBackground
          ? 'bg-transparent -mb-20 text-[color:var(--brand-text-on-primary-color)]'
          : selectedTheme
          ? 'bg-[color:var(--brand-primary-color)] text-[color:var(--brand-text-on-primary-color)]'
          : 'bg-white text-black' // White background with black text when no theme
      }`}>
      <div className="sm:container relative flex justify-between h-14">
        <div className="w-full flex flex-row justify-between items-center">
          <div className={`flex ${sidePaddingSizeValueL} justify-center gap-2`}>
            {leftButtons.map((button) => renderButton(button))}
          </div>

          {title !== undefined && (
            <div className="px-2">
              <Heading
                color={useWhiteNavbar ? 'default' : 'brand'}
                level={5}
                className="text-center overflow-hidden break-words line-clamp-1">
                {title}
              </Heading>
            </div>
          )}

          <div
            className={`flex basis-8 align-middle items-center justify-center ${sidePaddingSizeValueR} gap-2`}>
            {rightButtons.map((button) => renderButton(button))}
          </div>
        </div>
      </div>

      {leftButtons.includes(NavbarButton.Menu) && <Menu openMenu={openMenu} />}
    </nav>
  )
}
