import { createContext, useCallback, useContext, useMemo } from 'react'

import { LocationTheme } from '@/models/types'

// Default colors when no theme is provided
const defaultColors = {
  primaryColor: '#000000',
  textColor: '#ffffff',
}

// Create a theme context to share theme information
export const ThemeContext = createContext<LocationTheme | null>(null)

/**
 * Hook to get theme colors from context or use default colors
 * @returns An object with primary and text colors based on theme
 */
export const useThemeColors = () => {
  const theme = useContext(ThemeContext)

  const themeColors = useMemo(() => {
    if (!theme) {
      console.log('No theme available, using defaults:', defaultColors)
      return defaultColors
    }

    const colors = {
      primaryColor: theme.brandPrimaryColor || defaultColors.primaryColor,
      textColor: theme.brandColorTextOnPrimary || defaultColors.textColor,
    }
    console.log('Theme available:', colors)
    return colors
  }, [theme])

  const hasTheme = useMemo(() => {
    if (!theme) return false
    if (!theme.brandPrimaryColor) return false
    return theme.brandPrimaryColor !== defaultColors.primaryColor
  }, [theme])

  const getStyleProps = useCallback(() => {
    return {
      backgroundColor: themeColors.primaryColor,
      color: themeColors.textColor,
      borderColor: themeColors.primaryColor,
    }
  }, [themeColors])

  return {
    ...themeColors,
    hasTheme,
    getStyleProps,
  }
}
