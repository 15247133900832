import React from 'react'

import { ThemeContext } from '@/hooks/useThemeColors'
import { LocationTheme } from '@/models/types'

const CustomColors = ({
  brandPrimary,
  brandTextOnPrimary,
}: {
  brandPrimary?: string | null
  brandTextOnPrimary?: string | null
}) => {
  if (!brandPrimary || !brandTextOnPrimary) {
    return (
      <style jsx global>
        {`
          html {
            --brand-primary-color: #000;
            --brand-text-on-primary-color: #fff;
          }
        `}
      </style>
    )
  }
  return (
    <style jsx global>
      {`
        html {
          --brand-primary-color: ${brandPrimary};
          --brand-text-on-primary-color: ${brandTextOnPrimary};
        }
      `}
    </style>
  )
}

const CustomFont = ({
  fontFamily,
  fontPath,
}: {
  fontFamily?: string | null
  fontPath?: string | null
}) => {
  if (!fontFamily && !fontPath) {
    return null
  }

  return (
    <style jsx global>
      {`
        @import url('${fontPath}');

        html {
          --brand-font: ${fontFamily};
        }
      `}
    </style>
  )
}

const BrandTheme = ({ selectedTheme }: { selectedTheme?: LocationTheme | null }) => {
  return (
    <ThemeContext.Provider value={selectedTheme || null}>
      <CustomFont
        fontFamily={selectedTheme?.brandFontFamily}
        fontPath={selectedTheme?.brandFontPath}
      />
      <CustomColors
        brandPrimary={selectedTheme?.brandPrimaryColor}
        brandTextOnPrimary={selectedTheme?.brandColorTextOnPrimary}
      />
    </ThemeContext.Provider>
  )
}

export default BrandTheme
