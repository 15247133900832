import { ArrowLeftIcon } from '@heroicons/react/solid'
import { useRouter } from 'next/router'
import React from 'react'

import { LocationTheme } from '@/models/types'

type Props = {
  goBackToHref?: string
  buttonAction?: () => void
  invertColor: boolean
  shouldUseRouterBack?: boolean
  selectedTheme?: LocationTheme | null
}

export const BackButton = ({
  goBackToHref,
  buttonAction,
  invertColor,
  shouldUseRouterBack,
  selectedTheme,
}: Props) => {
  const router = useRouter()

  // Determine if we're in a white navbar with no theme
  const isInWhiteNavbar = !selectedTheme && !invertColor

  const handleRouteBack = () => {
    if (buttonAction) {
      buttonAction()
    }

    if (shouldUseRouterBack) {
      router.back()
      return
    }
    if (goBackToHref) {
      router.prefetch(goBackToHref)
      router.push(goBackToHref)
      return
    }
  }

  // Use CSS variable for themed text, with explicit colors for special cases
  const textColorClass = invertColor
    ? 'text-white'
    : isInWhiteNavbar
    ? 'text-black' // Black text when in white navbar with no theme
    : 'text-[color:var(--brand-text-on-primary-color)]' // Theme text color

  return (
    <button
      className={`inline-flex items-center justify-center p-1 rounded-full ${textColorClass}`}
      onClick={handleRouteBack}>
      <ArrowLeftIcon className="h-6 w-6" />
    </button>
  )
}
